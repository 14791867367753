import { Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewEncapsulation,  SimpleChanges } from '@angular/core';
import { Model, StylesManager, Serializer, CustomWidgetCollection } from 'survey-core';
import { Converter } from "showdown";
import { ChangeDetectorRef } from '@angular/core';
import {SessionService, SurveyService, EventService, SitePageService} from 'src/app/services';
import { DeviceDetectorService } from 'ngx-device-detector';
import {Router} from "@angular/router";
import {timer} from 'rxjs';
import * as SurveyCore from "survey-core";
import { inputmask } from "surveyjs-widgets";


StylesManager.applyTheme('modern');
Serializer.addProperty('page', 'sliderChoices');
Serializer.addProperty('page', 'timing');
Serializer.addProperty('page', 'isCustomWidgetCarosuel');
Serializer.addProperty('page', 'isWistaRequired');
Serializer.addProperty('page', 'parseValueAsInt');
//Add a custom 'popupdescription' property to questions of all types and to pages
Serializer.addProperty("question", "popupdescription:text");
Serializer.addProperty("page", "popupdescription:text");
inputmask(SurveyCore);
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SurveyComponent implements OnInit {
    @Input() quizData;
    @Output() completeEvent = new EventEmitter();
    @Output() partialEvent = new EventEmitter();
    @Output() createNewUserEvent = new EventEmitter();

    voidAddingQuestionName = [
      'lottieAnimation',
      'informational_slide',
      'informational_slide_btn',
      'importantWarning',
      'animation'
    ]
    customSliderComponentRef:any;
    pageNum = 0;
    customSliderLength = 0;
    surveyModel: Model;
    quizVersion: string = '';
    typeOfQuiz: string = '';
    quizSlug: string = '';
    contextual: boolean = false;
    smsOptOut: boolean = false;

    keyframesRightToCenter = [
      { opacity: 0, transform: 'translateX(100%)' },
      { opacity: 0.2, transform: 'translateX(75%)' },
      { opacity: 0.5, transform: 'translateX(25%)' },
      { opacity: 1, transform: 'translateX(0%)' },
    ];

    keyframesCenterToLeft = [
      { opacity: 1, transform: 'translateX(0%)' },
      { opacity: 0.5, transform: 'translateX(-25%)' },
      { opacity: 0.2, transform: 'translateX(-75%)' },
      { opacity: 0, transform: 'translateX(-100%)' },
    ];

    constructor(public elRef: ElementRef,
                private ref: ChangeDetectorRef,
                private deviceService: DeviceDetectorService,
                private sessionService:SessionService,
                private router: Router,
                private surveyService: SurveyService,
                private eventService: EventService,
                private sitePageService: SitePageService) { }

    slideIndex = 1;
    searchStringWidget: any;

    ngOnInit(): void {

      // Initiate event listener for mobile viewport to handle virtual keyboard - Workaround for bottom fixed navigation bar
      if (this.deviceService.isMobile()) {
        window.visualViewport.addEventListener('resize', event => {
          const isKeyboardHidden = ((window.innerHeight / window.screen.availHeight) > 0.6);
          if (isKeyboardHidden) {
            window.scrollBy(0, 1); // 0 pixels horizontal and 1 pixel down
          }
        });
      }

    }

    ngOnChanges(changes: SimpleChanges) {
      if(changes.quizData.currentValue) {
        this.quizVersion = changes.quizData.currentValue.name;
        this.quizSlug = changes.quizData.currentValue.slug;
        this.contextual = changes.quizData.currentValue.contextual;
        const survey = new Model(changes.quizData.currentValue.questions, this);
        const prevData = window.localStorage.getItem(this.sessionService.quizItemKey) || null;
        if (prevData) {
          const data = JSON.parse(prevData);
          survey.data = data;
          if (data.pageNo) {
            survey.currentPageNo = data.pageNo;
          } else {
            this.addQuizStartedEvent();
          }
        } else {
          this.addQuizStartedEvent();
        }

        // Override/Add Onto any existing surveyjs css attributes
        let customCss = changes.quizData.currentValue.questions?.customCss;
        if(customCss) {
          survey.css = customCss;
        }

        let isWistaRequired = changes.quizData.currentValue.questions?.isWistaRequired;
        if(isWistaRequired) {
          this.sitePageService.createScript('https://fast.wistia.com/assets/external/E-v1.js');
        }

        survey.setPropertyValue('thisRef', this);
        survey.focusFirstQuestionAutomatic = false;
        survey.onAfterRenderPage.add(this.afterRenderPage);
        survey.onPartialSend.add(this.partialComplete);
        survey.onComplete.add(this.surveyComplete);
        survey.onAfterRenderQuestion.add(this.afterRenderQuestion);
        survey.onTextMarkdown.add(this.textMarkdown);
        survey.onCurrentPageChanging.add(this.currentPageChanging);
        survey.onCurrentPageChanged.add(this.currentPageChanged);
        survey.onValidateQuestion.add(this.validateQuestion);
        survey.onServerValidateQuestions.add(this.validateEmail);
        survey.onValueChanged.add(this.onValueChangedFun);
        survey.onUpdateQuestionCssClasses.add(this.onUpdateCss);
        survey.clearInvisibleValues = "none";
        this.customWidget(survey, changes.quizData.currentValue.questions);
        CustomWidgetCollection.Instance.add(this.searchStringWidget);
        this.surveyModel = survey;
      }
    }

    ngAfterViewInit(): void {
      let surveyVideoPlayer: HTMLVideoElement = document.querySelector('video.video-animation');

      // Use Case where user returns with token and lands on animation page.
      // Video will not auto play in some cases
      timer(100)
        .subscribe(val => {
          if(surveyVideoPlayer && surveyVideoPlayer.paused) {
            surveyVideoPlayer.play();
          }
        });
    }

    addQuizStartedEvent() {
      this.sendEvents('quiz started', { 'quiz_version': this.quizSlug });
      this.eventService.sendRedtrackEvent('quiz-starts');
    }

    onUpdateCss(_, options) {
      const classes = options.cssClasses;
      if (options.question.getType() === 'checkbox') {
        classes.root += ' custom-two-col-checkbox';
      }
    }

    onValueChangedFun(survey, options) {
      const a = options?.question?.getType();
      let thisRef = survey.getPropertyValue('thisRef');
      if (a === 'radiogroup' || a === 'imagepicker') {
        if (survey.data[survey.currentPage.name] == 'other') {
          if(survey.data[survey.currentPage.name + -Comment] == undefined) {
            survey.nextPage();
          }
        } else {
          let element = document.querySelector('.sd-page');
          setTimeout(() => {
            thisRef
              .animateElement(thisRef.keyframesCenterToLeft, element, 300)
              .then((res) => {
                if(survey.isLastPage) {
                  survey.doComplete();
                } else {
                  survey.nextPage();
                }
                // survey.nextPage();
              });
          }, 300);
        }
      }
    }

    customWidget(survey, quizData) {
      let pointer = this;
      this.searchStringWidget = {

        //the widget name. It should be unique and written in lowercase.
        name: "customimage",
        //SurveyJS library calls this function for every question to check
        //if this widget should apply to the particular question.
        isFit: function (question) {
          //We are going to apply this widget for comment questions (textarea)
          return question.getType() == "expression";
        },
        //We will change the default rendering, but do not override it completely
        isDefaultRender: true,
        //"question" parameter is the question we are working with and "el" parameter is HTML textarea in our case
        afterRender: function (question, el) {

          let sectionTemplate = document.createElement('div');
          let dotDiv = document.createElement('div');
          let ul = document.createElement('ul');
          dotDiv.setAttribute('class','slider-dot-container');
          sectionTemplate.setAttribute('id','slider-wrapper');
          sectionTemplate.setAttribute('class','slider-wrapper');
          ul.setAttribute('id','slides-container');
          ul.setAttribute('class','slides-container');
          const choices = quizData.pages.find(obj => obj.name === question.name);
          choices.sliderChoices?.forEach(function (element, index, arr) {

            // if (arr[index].selected) {
            // this logic failing which user goes on previous question instead of loading from local storage as selected answer
            // its picking default values.
            //   const npsQuestion = survey.getQuestionByName(survey.currentPage.name);
            //   npsQuestion.value = arr[index].value;
            // }

            pointer.customSliderLength = choices.sliderChoices.length;
            var li = document.createElement('li');
            li.setAttribute('class','slide');

            ul.appendChild(li);
            let image = document.createElement('img');
            let video = document.createElement('video');

            if(arr[index].imageLink) {
              image.setAttribute('class','mySlide-img');
              image.setAttribute('id',index + 1);
              image.setAttribute('data-selected',arr[index].selected);
              image.setAttribute('src',arr[index].imageLink);
            }
            if(arr[index].videoLink) {
              video.setAttribute('class','mySlide-img');
              video.setAttribute('id',index + 1);
              video.setAttribute('data-selected',arr[index].selected);
              video.setAttribute('src',arr[index].videoLink);
              video.autoplay = true;
              video.loop = true;
              video.muted = true; // fixes autoplay in chrome
              video.setAttribute('playsinline', 'true'); // fixes autoplay in webkit (ie. mobile safari)
              video.controls = false;
            }

            let textDiv = document.createElement('div');
            textDiv.setAttribute('class','mySlide-img-text');
            textDiv.setAttribute('id',arr[index].value);
            textDiv.innerText = arr[index].text;

            let spanDot = document.createElement('span');
            spanDot.setAttribute('class','dot');
            spanDot.setAttribute('id',index + 1);

            let lineTick = document.createElement('span');
            lineTick.setAttribute('class','line');

            dotDiv.appendChild(spanDot);
            if(index < arr.length -1) {
              dotDiv.appendChild(lineTick);
            }
            arr[index].imageLink? li.appendChild(image): '';
            arr[index].videoLink? li.appendChild(video): '';
            li.appendChild(textDiv);
          });
          sectionTemplate.appendChild(ul);
          let btnPrev = document.createElement('button');
          let btnNext = document.createElement('button');
          btnNext.setAttribute('class','slide-arrow');
          btnPrev.setAttribute('class','slide-arrow');
          btnPrev.setAttribute('id','slide-arrow-prev');
          btnNext.setAttribute('id','slide-arrow-next');

          let imagePrev = document.createElement('img');
          imagePrev.setAttribute('class','slide-navigation');
          imagePrev.setAttribute('src', '/assets/images/quiz/Arrow-left.png');

          let imageNext = document.createElement('img');
          imageNext.setAttribute('class','slide-navigation');
          imageNext.setAttribute('src', '/assets/images/quiz/Arrow-right.png');

          btnPrev.appendChild(imagePrev);
          btnNext.appendChild(imageNext);

          let navigationDiv = document.createElement('div');
          navigationDiv.setAttribute('class', 'custom_navigation-carousel');

          let navigatePrev = document.createElement('button');
          navigatePrev.setAttribute('class', 'custom_navigation-carousel-previous previous_btn');
          navigatePrev.innerHTML = 'Previous';

          let navigateNext = document.createElement('button');
          navigateNext.setAttribute('class', 'custom_navigation-carousel-next question_next_btn');
          navigateNext.innerHTML = 'Next';

          let imageNextArrow = document.createElement('img');
          imageNextArrow.setAttribute('src', '/assets/images/next-circle.svg');

          navigateNext.appendChild(imageNextArrow);
          navigationDiv.appendChild(navigatePrev);
          navigationDiv.appendChild(navigateNext);
          sectionTemplate.appendChild(btnPrev);
          sectionTemplate.appendChild(btnNext);
          // Append the div with search input and button before textarea
          el.parentElement.insertBefore(sectionTemplate, el);
          el.parentElement.insertBefore(dotDiv, el);
          el.parentElement.insertBefore(navigationDiv, el);

          // var onValueChangedCallback = function () {
          //   console.log('value changed....1!!');
          // }
          // question.valueChangedCallback = onValueChangedCallback;

        },
        willUnmount: function (question, el) {
          // console.log('destroy is called...!!');
          //We do not need to clear anything in our simple example
          //Here is the example to destroy the image picker
          //var $el = $(el).find("select");
          //$el.data('picker').destroy();
        }
      };
    }

    validateEmail(survey, { data, errors, complete }) {
      let thisRef = survey.getPropertyValue('thisRef');
      const email = data["email"];
      const smsOptIn = data["phone_terms"];

      if (!email && !smsOptIn) {
        complete();
        return;
      } else {
        if(email)
          thisRef.createQuizUser(survey, data, errors, complete);
        else if(smsOptIn)
        {
          thisRef.createSMSContact(survey, data, errors, complete);
        }
      }
    }

    createQuizUser(survey, data, errors, complete) {
      const surveyReq = survey.data;
      surveyReq.pageNo = survey.currentPageNo;
      surveyReq.curentPageName = survey.currentPage.name;
      surveyReq.versionSlug = this.quizSlug;
      surveyReq.version = this.quizVersion;
      Object.keys(this.sessionService.referralInfo).forEach(element => {
        if(this.sessionService.getReferralInfo(element)) {
          surveyReq[element] = this.sessionService.getReferralInfo(element);
        }
      });
      // TODO: Email Question is failing to create a user
      this.surveyService.saveNewQuizResponse(surveyReq).subscribe({
        next: res => {
          // Successful response, redirect to next page
          // console.log(res);
          window.localStorage.setItem(this.sessionService.quizItemKey, JSON.stringify(surveyReq));
          this.createNewUserEvent.emit(res);
          this.sessionService.weshapeToken = res.res;
          complete();
        },
        error: err => {
          // console.log(err.error.message);
          errors["email"] = err.error.message;
          complete();
        }
      });
    }

    createSMSContact(survey, data, errors, complete)
    {
      const smsTerms = data.phone_terms;
      const number = data.phone_number;
      if(smsTerms == true && !number)
      {
        errors["phone_number"] = "Enter a valid phone number";
        complete();
        return;
      }
      if(smsTerms == true)
      {
        const surveyReq = survey.data;
        surveyReq.pageNo = survey.currentPageNo;
        surveyReq.curentPageName = survey.currentPage.name;
        surveyReq.versionSlug = this.quizSlug;
        surveyReq.version = this.quizVersion;

        this.surveyService
        .smsOptInAndUpdateQuizResponse(surveyReq, this.sessionService.getWeshapeToken())
        .subscribe({
          next: res => {
            // Successful response, redirect to next page
            complete();
          },
          error: err => {
            errors["phone_number"] = err.error.message;
            complete();
          }
        });

      }
    }

    validateQuestion(survey, options)
    {
      if (options.name === "names") {
        let myRegex = /^[A-ZÀ-ÿa-z0-9]+((\s)?((\'|\-|\.|\+|\@)?([A-ZÀ-ÿa-z0-9])+))*$/;
        if (options.value.first_name && options.value.last_name) {
          if(!myRegex.test(options.value.first_name.trim()))
            options.error = 'Please enter a valid first name';
          if(!myRegex.test(options.value.last_name.trim()))
            options.error = 'Please enter a valid last name';
        } else {
          options.error = 'Response required';
        }
      }
    }

    currentPageChanged(survey, options)
    {
      // console.log('currentPageChanged');
      let thisRef = survey.getPropertyValue('thisRef');
      if((options.newCurrentPage.name || options.oldCurrentPage.name) == 'goal'
      || (options.newCurrentPage.name || options.oldCurrentPage.name) == 'challenge'
      || (options.newCurrentPage.name || options.oldCurrentPage.name) == 'phone') {
        thisRef.elRef.nativeElement.querySelector('.sd-progress').classList.add('d-none')
        thisRef.elRef.nativeElement.querySelector('.sd-root-modern').classList.add('bg-blue');
        thisRef.elRef.nativeElement.querySelector('.custom_navigation')?.classList.add('bg-blue');
      } else {
        thisRef.elRef.nativeElement.querySelector('.sd-progress').classList.remove('d-none')
        thisRef.elRef.nativeElement.querySelector('.sd-root-modern').classList.remove('bg-blue');
        thisRef.elRef.nativeElement.querySelector('.custom_navigation')?.classList.remove('bg-blue');
      }

      // Log event slide change with current url
      thisRef.sendEvents('$pageview');
    }

    currentPageChanging(survey, options)
    {
      let element = document.querySelector('.sd-page');
      let thisRef = survey.getPropertyValue('thisRef');
      let that = survey.getPropertyValue('thisRef');
      thisRef
      .animateElement(thisRef.keyframesRightToCenter, element, 300)
      .then((res) => {
        //this.count = 0;
      });
    }

    textMarkdown(survey, options)
    {
      //Create showdown markdown converter
      let converter = new Converter();
      let str = converter.makeHtml(options.text);
      //remove root paragraphs <p></p>
      str = str.substring(3);
      str = str.substring(0, str.length - 4);
      //set html
      options.html = str;
    }

    partialComplete(survey, options)
    {
      let thisRef = survey.getPropertyValue('thisRef');
      const email = survey.getQuestionByName("email");
      if (!thisRef.voidAddingQuestionName.find(e => (survey.currentPage.name).includes(e))) {
        // If They Select This Option "I Don't Currently Work Out" Can We Skip The Questions Below “How Many Days A Week Do You Consistently Work Out” As Well As
        // “I Have An Established Workout Routine” And Mark Their Answers As “I Don’t Consistently Work Out” And “1 - Strongly Disagree”?
        if(survey.curentPageName == 'exercise_experience') {
          if(survey.data['exercise_experience'] == 'ne') {
            survey.getQuestionByName('energy_and_strength3').value = 1;
          } else {
            survey.getQuestionByName('energy_and_strength3').value = '';
          }
        }
        const data = survey.data;
        if(thisRef.smsOptOut) {
          thisRef.smsOptOut = false;
          survey.ignoreValidation = false;
          data['phone_number'] = "";
          data['phone_terms'] = false;
        }
        data.pageNo = survey.currentPageNo;
        data.curentPageName = survey.currentPage.name;
        data.versionSlug = thisRef.quizSlug;
        data.version = thisRef.quizVersion;
        window.localStorage.setItem(thisRef.sessionService.quizItemKey, JSON.stringify(data));
        thisRef.partialEvent.emit(data);
      }
    }

    surveyComplete(survey)
    {
      let thisRef = survey.getPropertyValue('thisRef');
      const data = survey.data;
      data.pageNo = survey.currentPageNo;
      data.curentPageName = survey.currentPage.name;
      data.versionSlug = thisRef.quizSlug;
      data.version = thisRef.quizVersion;
      if(data['programs-tried'] && data['diet-methods'] && data['fitness-methods']) {
        if (data['programs-tried'].indexOf("none") !== -1) {
          data['programs-tried'] = [];
        }
        if (data['diet-methods'].indexOf("none") !== -1) {
          data['diet-methods'] = [];
        }
        if (data['fitness-methods'].indexOf("none") !== -1) {
          data['fitness-methods'] = [];
        }
        data.total_programs_tried = data['programs-tried'].length + data['diet-methods'].length + data['fitness-methods'].length;
      }
      window.localStorage.setItem(thisRef.sessionService.quizItemKey, JSON.stringify(data));
      thisRef.completeEvent.emit(data);
    }


    afterRenderQuestion (survey, options)
    {
        let pointer = survey.getPropertyValue('thisRef');
        if (location.origin.indexOf("localhost") != -1 && survey.currentPage.elements[0].html && (survey.currentPage.name.indexOf('animation') > -1)){
          if(survey.currentPage.elements[0].html.indexOf('https://development.weshape.com/') == -1) {
            survey.currentPage.elements[0].html = (survey.currentPage.elements[0].html).replaceAll("/dynamic-assets/", "https://development.weshape.com/dynamic-assets/");
          }
        }
        pointer.slideIndex = 1;
        // pushState: This will create a new entry in the browser's history, without reloading
        // replaceState: This will replace the current entry in the browser's history, without reloading
        let url = pointer.router.url;
        // console.log('Angular routing router ', url);
        // console.log('window.location.pathname ', window.location.pathname)
        // Check if the URL has token then replace the token with quiz version/info
        if (url.match(/\?./))
          url = url.split('?')[0];
        let path = url + '/' + pointer.quizSlug;
        if (pointer.contextual && pointer.typeOfQuiz)
          path = url + '/' + pointer.quizSlug + '/' + pointer.typeOfQuiz;
        var quizUrl = path + '/' + survey.currentPage.name;
        if(!pointer.voidAddingQuestionName.find(e => (survey.currentPage.name).includes(e)))
          history.replaceState({}, "", path + '/' + survey.currentPage.name);
        else
          history.replaceState({}, "", path + '/info');

      //Register our widget in singleton custom widget collection

        const prevButton = document.querySelectorAll("#slide-arrow-prev");
        const nextButton = document.querySelectorAll("#slide-arrow-next");
        const slide = document.querySelectorAll(".slide");
        const dotButton = document.querySelectorAll(".dot");

        if(survey.currentPage.elements[0].name == 'doComplete') {
          setTimeout(() => {
            survey.doComplete();
          }, 2000)
        }

        let setSelectedIndex = function () {
          if (survey.data[survey.currentPage.name]) {
            [].forEach.call(slide, function(el) {
              el.querySelector('.mySlide-img').classList.remove("mySlide-img-active");
              if(el.querySelector('.mySlide-img-text').id == survey.data[survey.currentPage.name]) {
                pointer.slideIndex = parseInt(el.querySelector('.mySlide-img').id);
                el.querySelector('.mySlide-img').className += " mySlide-img-active";
                pointer.showActiveSliderDot(pointer.slideIndex);
                const slideWidth = document.querySelector(".slide").clientWidth;
                document.querySelector("#slides-container").scrollLeft += slideWidth * (pointer.slideIndex - 1);
              }
            });
          } else {
            [].forEach.call(slide, function(el) {
              el.querySelector('.mySlide-img').classList.remove("mySlide-img-active");
              if(el.querySelector('.mySlide-img').getAttribute('data-selected') === 'true') {
                pointer.slideIndex = parseInt(el.querySelector('.mySlide-img').id);
                el.querySelector('.mySlide-img').className += " mySlide-img-active";
                pointer.showActiveSliderDot(pointer.slideIndex);
                const slideWidth = document.querySelector(".slide").clientWidth;
                document.querySelector("#slides-container").scrollLeft += slideWidth * (pointer.slideIndex - 1);
                const npsQuestion = survey.getQuestionByName(survey.currentPage.name);
                npsQuestion.value = survey.currentPage.parseValueAsInt? parseInt(el.querySelector('.mySlide-img-text').id) : el.querySelector('.mySlide-img-text').id;
                survey.data[survey.currentPage.name] = survey.currentPage.parseValueAsInt? parseInt(el.querySelector('.mySlide-img-text').id) : el.querySelector('.mySlide-img-text').id;
              }
            });
          }
        }

        setTimeout(() => {
          if(survey.currentPage.isCustomWidgetCarosuel) {
            setSelectedIndex();
          }
        }, 300);

        let onSlideChange = function () {
          [].forEach.call(slide, function(el) {
            el.querySelector('.mySlide-img').classList.remove("mySlide-img-active");
            if(parseInt(el.querySelector('.mySlide-img').id) === pointer.slideIndex) {
              el.querySelector('.mySlide-img').className += " mySlide-img-active";
              const npsQuestion3 = survey.getQuestionByName(survey.currentPage.name);
              npsQuestion3.value = survey.currentPage.parseValueAsInt? parseInt(el.querySelector('.mySlide-img-text').id) : el.querySelector('.mySlide-img-text').id;
              survey.data[survey.currentPage.name] = survey.currentPage.parseValueAsInt? parseInt(el.querySelector('.mySlide-img-text').id) : el.querySelector('.mySlide-img-text').id;
            }
          });
        }

        nextButton.forEach((btn, index) => btn.addEventListener("click", () => {
          if(pointer.slideIndex < pointer.customSliderLength) {
            const slideWidth = document.querySelector(".slide").clientWidth;
            document.querySelector("#slides-container").scrollLeft = slideWidth * pointer.slideIndex;
            pointer.slideIndex += 1;
            pointer.showActiveSliderDot(pointer.slideIndex);
            onSlideChange();
          }
        }));

        prevButton.forEach((btn, index) => btn.addEventListener("click", () => {
          if(pointer.slideIndex != 1) {
            const slideWidth = document.querySelector(".slide").clientWidth;
            document.querySelector("#slides-container").scrollLeft -= slideWidth;
            pointer.slideIndex -= 1;
            pointer.showActiveSliderDot(pointer.slideIndex);
            onSlideChange();
          }
        }));

        dotButton.forEach((btn, index) => btn.addEventListener("click", () => {
          let buttonID = parseInt(btn.id);
          if (buttonID == pointer.slideIndex) {
            // console.log('Stay on the same slide');
          }
          if (buttonID > pointer.slideIndex) {
            let value = buttonID - pointer.slideIndex;
            const slideWidth = document.querySelector(".slide").clientWidth;
            document.querySelector("#slides-container").scrollLeft += slideWidth * value;
            pointer.slideIndex += value;
            pointer.showActiveSliderDot(pointer.slideIndex);
            onSlideChange();
          }
          if (buttonID < pointer.slideIndex) {
            let value = pointer.slideIndex - buttonID;
            const slideWidth = document.querySelector(".slide").clientWidth;
            document.querySelector("#slides-container").scrollLeft -= slideWidth * value;
            pointer.slideIndex -= value;
            pointer.showActiveSliderDot(pointer.slideIndex);
            onSlideChange();
          }
        }));

        /*Update weekly_workouts maximum choices allowed based on workout_days answer*/
        const workout_days = survey.getQuestionByName("workout_days");
        const weekly_workouts = survey.getQuestionByName("weekly_workouts");
        if(workout_days && weekly_workouts) {
          let text = weekly_workouts.value + " workouts per week"
          survey.setVariable("weekly_workouts_display_text", text);
          workout_days['maxSelectedChoices'] = weekly_workouts.value;
          if(workout_days.value.length > 0) {
            let sortingArr = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
            let itemsArray = workout_days.value.map( a => a.charAt(0).toUpperCase() + a.substr(1) );
            itemsArray.sort(function(a, b){
              return sortingArr.indexOf(a) - sortingArr.indexOf(b);
            });
            survey.setVariable("workout_days_display_text", itemsArray.join(', '));
          }
        }

        if(pointer.contextual)
          pointer.updateVariables(survey);

        const video = pointer.elRef.nativeElement.querySelectorAll('.video-animation');
        const nextIcon = pointer.elRef.nativeElement.getElementsByClassName('animation_next');
        const skipAnimation = pointer.elRef.nativeElement.getElementsByClassName('skip_animation');
        const doComplete = pointer.elRef.nativeElement.getElementsByClassName('do-complete');
        if (video) {
          video.forEach((btn) => {
            btn.onplay = (event) => {
              if (skipAnimation && skipAnimation.length > 0) {
                skipAnimation[0].addEventListener('click', function () {
                  //amplitude event
                  pointer.sendEvents('skip button clicked',{'quiz_version':pointer.quizSlug,
                  'url':quizUrl,
                  'challenge': survey.data["challenge"],
                  'context':survey.getVariable("challenge_display")});
                });
                setTimeout(() => {
                  skipAnimation[0].classList.remove("d-none");
                }, survey.currentPage.timing);
              }
            };
            btn.onended = (event) => {
              // console.log("Video stopped either because it has finished playing or no further data is available.");
              if (nextIcon && nextIcon.length > 0) {
                nextIcon[0].classList.remove("d-none");
                if (skipAnimation && skipAnimation.length > 0)
                    skipAnimation[0].classList.add("d-none");
              } else if (doComplete && doComplete.length > 0) {
                pointer
                .animateElement(pointer.keyframesCenterToLeft, element, 300)
                .then((res) => {
                  survey.doComplete();
                });
              } else {
                pointer
                .animateElement(pointer.keyframesCenterToLeft, element, 300)
                .then((res) => {
                  survey.nextPage();
                });
              }
            }
          });

        }
        /*update lottie player to goto next page on complete*/
        const player = pointer.elRef.nativeElement.querySelectorAll('lottie-player');
        if (player) {
          player.forEach((btn) => btn.addEventListener('complete', function () {
            pointer
            .animateElement(pointer.keyframesCenterToLeft, element, 300)
            .then((res) => {
              survey.nextPage();
            });
          }))
        }

        /*Update lottie src based on isMobile flag which is provided by survey js*/
        if(survey.currentPage.name === 'lottieAnimation' && pointer.deviceService.isMobile()) {
          const player2 = pointer.elRef.nativeElement.querySelector("lottie-player");
          player2.addEventListener("rendered", (e) => {
            let src = survey.currentPage.elements[0].title + '-Mobile.json'
            player2.load(src);
            player2.play();
          });
        }

        /* SMS OPT-OUT*/
        const smsOptOut = pointer.elRef.nativeElement.querySelector('.phone_navigation_opt_out');
        smsOptOut?.addEventListener('click', function () {
          // console.log('******* WRITE LOGIC TO SMS OPT OUT HERE ********');
          survey.ignoreValidation = true; // ignore validation for going to next slide
          pointer.smsOptOut = true;
          survey.nextPage();
        });

        /*update button on information_slide to goto next page*/
        const nextBtns = pointer.elRef.nativeElement.querySelectorAll(".next_btn");
        let element = document.querySelector('.sd-page');
        nextBtns.forEach((btn) => btn.addEventListener('click', function () {
         pointer
         .animateElement(pointer.keyframesCenterToLeft, element, 300)
         .then((res) => {
            survey.nextPage();
          });
        }))

        /*update button on information_slide to goto next page*/
        const questionnextBtns = pointer.elRef.nativeElement.querySelectorAll(".question_next_btn");
        let element1 = document.querySelector('.sd-page');
        questionnextBtns.forEach((btn) => btn.addEventListener('click', function () {
          if(survey.isLastPage) {
            survey.doComplete();
          }

          if (Array.isArray(survey.data[survey.currentPage.name]) && survey.data[survey.currentPage.name].includes('other')) {
            if(survey.data[survey.currentPage.name + -Comment] == undefined) {
              survey.nextPage();
            }
          } else if (options.value || survey.data[survey.currentPage.name]) {
            pointer.animateElement(pointer.keyframesCenterToLeft, element1, 300)
            .then((res) => {
              survey.nextPage();
            });
          } else {
            survey.nextPage();
          }

        }))

        /*update button on information_slide to goto next page*/
        const previousBtns = pointer.elRef.nativeElement.querySelectorAll(".previous_btn");
        let element2 = document.querySelector('.sd-page');
        previousBtns.forEach((btn) => btn.addEventListener('click', function () {
          survey.prevPage();
        }))

        const emailBtn = pointer.elRef.nativeElement.querySelector(".email_btn");
        if(emailBtn) {
          const element = document.querySelector('.sd-page');
            emailBtn.addEventListener('click', function () {
              survey.nextPage();
              // thisRef
              // .animateElement(thisRef.keyframesCenterToLeft, element, 300)
              // .then((res) => {
              //   survey.nextPage();
              // });
            })
        }
        const completeBtn = pointer.elRef.nativeElement.querySelectorAll(".complete_btn");
        if(completeBtn)
        {
          completeBtn.forEach((btn) =>
          {
            btn.addEventListener('click', function () {
              survey.doComplete();
            });
          });
        }
        const back = pointer.elRef.nativeElement.querySelector(".policyBackButton");
        if(back) {
          back.addEventListener('click', function () {
            survey.prevPage();
          })
        }

        /*update popup modal on click why is this important*/
        if(options.question.popupdescription) {
          const description = pointer.elRef.nativeElement.querySelectorAll(".sd-question__description");
          if(description) {
            description.forEach((btn) => btn.addEventListener('click', function () {
              pointer.elRef.nativeElement.querySelector("#questionDescriptionText").innerHTML = options.question.popupdescription;
              let popupEl = pointer.elRef.nativeElement.querySelector("#questionDescriptionPopup");
              pointer.elRef.nativeElement.querySelector("#questionDescriptionPopupClose").onclick = () => { popupEl.close(); }
              popupEl.showModal();
            }));
          }
        }
    }

    afterRenderPage(survey, options)
    {
      let that = survey.getPropertyValue('thisRef');
      // console.log('onAfterRenderPage');
    }

    updateVariables(survey) {
      let pointer = survey.getPropertyValue('thisRef');
      let goal = survey.data["goal"];
      let challenge = survey.data["challenge"];
      let title_time_attempted, title_workouts_tried, challenge_display, challenges_display = '';
      if (goal && challenge) {
        switch (goal) {
          case 'fby':
            if (challenge == 'yw') {
              challenge_display = 'your weight';
              challenges_display = 'their weight';
            }
            if (challenge == 'wtu') {
              challenge_display = 'wanting to tone up';
              challenges_display = 'lack of tone';
            }
            if (challenge == 'ysc') {
              challenge_display = 'your self confidence';
              challenges_display = 'their lack of self confidence';
            }
            if (challenge == 'ycst') {
              challenge_display = 'your critical self talk';
              challenges_display = 'their critical self talk';
            }
            if (challenge == 'cyo') {
              challenge_display = 'comparing yourself to others';
              challenges_display = 'comparing themselves to others';
            }
            title_workouts_tried = 'In the past 2 years, what have you tried to improve '+ challenge_display + '?';
            if (challenge == 'fby') {
              challenge_display = 'feeling bad about how your body looks';
              challenges_display = 'how their body looks';
              title_workouts_tried = 'In the past 2 years, what have you done to help you overcome your struggle with feeling bad about how your body?';
            }
            this.typeOfQuiz = goal;
            title_time_attempted = 'How long have you been struggling with '+ challenge_display + '?';
            break;
          case 'fbb':
            this.typeOfQuiz = challenge;
            if (challenge == 'api') {
              challenge_display = 'aches, pains or injuries';
              title_time_attempted = 'How long have you been struggling with '+ challenge_display + '?';
              title_workouts_tried = 'In the past 2 years, what have you tried to help fix your aches, pains or injuries?'
            }
            if (challenge == 'ilm') {
              challenge_display = 'inflexible and lack of mobility';
              title_time_attempted = 'How long have you been struggling with your lack of flexibility?';
              title_workouts_tried = 'In the past 2 years, what have you tried to help become more flexible?';
            }
            if (challenge == 'cld') {
              challenge_display = 'confusion and lack of direction';
              title_time_attempted = 'How long have you felt confused about what workout would be best for you?';
              title_workouts_tried = 'In the past 2 years, what fitness programs have you tried?';
            }
            if (challenge == 'wls') {
              challenge_display = 'weakness and lack of strength';
              title_time_attempted = 'How long have you been struggling with your lack of strength?';
              title_workouts_tried = 'In the past 2 years, what have you tried to help become stronger?';
            }
            if (challenge == 'fby') {
              challenge_display = 'confusion and lack of direction';
              title_time_attempted = 'How long have you felt confused about what workout would be best for you?';
              title_workouts_tried = 'In the past 2 years, what fitness programs have you tried?';
            }
            break;
        }
        survey.setVariable("title_time_attempted", title_time_attempted);
        survey.setVariable("title_workouts_tried", title_workouts_tried);
        survey.setVariable("challenge_display", challenge_display);
        survey.setVariable("challenges_display", challenges_display);
      }
    }

    showActiveSliderDot(index) {
      const dots = document.querySelectorAll(".dot");
      [].forEach.call(dots, function(el) {
        el.classList.remove("active");
        if(parseInt(el.id) === index) {
          // console.log('button id: ' + el.id + ' thisPointer.slideIndex: ' + index + ' adding active to dots');
          el.className += " active";
        }
      });
      const prev = document.getElementById('slide-arrow-prev');
      prev.classList.remove("grey-scale");
      const next = document.getElementById('slide-arrow-next');
      next.classList.remove("grey-scale");
      if(index == 1) {
        prev.className += " grey-scale";
      }
      if(index == this.customSliderLength) {
        next.className += " grey-scale";
      }
    }

    sendEvents(event, eventProperty = {}) {
      this.eventService.sendEvent({
          event: event,
          event_properties: eventProperty
      });
    }

    animateElement(
      keyframe: any,
      element: Element,
      duration: number
    ): Promise<any> {
      return new Promise((resolve, reject) => {
          element.animate(keyframe, { duration: duration }).onfinish = () => {
            resolve('true');
          };
      });
    }

}
